@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oleo+Script:wght@400;700&display=swap');

@font-face {
    font-family: Phantom;
    src: url(../fonts/phantomstorm.ttf);
}

@media screen and (max-width: 1072px) {
    #title-container {
        max-width: 100%;
    }
}

@media screen and (max-width: 973px) {
    #top-nav {
        display: none;
    }
}

@media screen and (max-width: 650px) {
    #contactPage {
        padding-left: 30px;
        padding-right: 30px;
        padding-top: 40px;
        padding-bottom: 40px;
    }
}

@media screen and (max-width: 615px) {
    #product{
        width: 100%;
    }
}

@media screen and (max-width: 475px) {
    #title-container-sections{
        max-width: 100%;
    }
}

@media screen and (max-width: 450px) {
    #title {
        width: 100%;
    }
}

Button {
    font-family: "Montserrat", sans-serif;
}

*, html {
    color-scheme: only light;
}

#bg-img {
    background-image: linear-gradient(#FAE6B3, #FAE6B3);
    background-color: #000000;
}

#redhatdisplay {
    font-family: 'Red Hat Display', sans-serif;
    font-size: 1.5em;
    text-transform: uppercase;
}

#title {
    font-family: "Phantom", sans-serif;
    line-height: normal;
}

#phantom {
    font-family: "Phantom", sans-serif;
    line-height: normal;
}

#cardTitle {
    font-family: 'Oleo Script', sans-serif; 
    font-size: 1.5em;
}

#paragraph {
    font-family: 'Open Sans', sans-serif;
    color: #444434;
    margin-top: 15px;
}

#aboutus {
    font-family: 'Open Sans', sans-serif;
    color: #C7C9DD;
    margin-top: 15px;
    font-size: 1.15em;
}

#cardP {
    font-family: 'Open Sans', sans-serif;
    color: #A4A5B1;
    margin-top: 5px;
    font-size: 13px;
}

#cardTick {
    font-family: 'Open Sans', sans-serif;
    color: #DCDDE3;
    font-size: 14px;
    opacity: 0.9;
}

#roboto {
    font-family: "Roboto", sans-serif;
    color: #D7DAF1;
    font-size: 3em;
}
